import { Spinner, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isDuplicateNameError, useAddSegment, useGetSegment, useUpdateSegment } from "api/crud";
import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector, RootState, getAuth } from "store";
import { Operation, SegmentsAttributeInputType } from "types/services/segments.definitions";
import {
  filterOutMandatoryFormConditions,
  filterOutMandatoryQueryItems,
  mapQueryToFormConditions,
  mapToQuery,
} from "../utils/segmentDataTransformation";
import { LAST_LOGIN_COUNTRY, REGISTER_COUNTRY } from "../constants";
import { useMergedSegmentAttributes } from "api/segmentsAllRegions";
import { FormValues, SegmentForm } from "./SegmentForm";
import { useNotification } from "hooks/useNotification";
import { useNavigation } from "hooks/useNavigation";
import { applySegmentIntoFormValues, formInitValues, useSaveSegment, useSegmentNotifications } from "./utils";
import { SegmentPageTitle } from "./components";

export const DuplicateSegment = () => {
  const params = useParams();
  const navigate = useNavigation();
  const {
    attributes,
    categories,
    isLoading: isAttributesLoading,
    error: attributesError,
  } = useMergedSegmentAttributes();
  const { mutate: add, isSuccess: addSuccess, error: addError, isLoading: addIsLoading } = useAddSegment();
  const { isLoading: isSegmentLoading, data: segmentResp, isError: segmentError } = useGetSegment(params.id as string);
  const duplicate = useSaveSegment(add);
  const [formValues, setFormValues] = useState<FormValues>(formInitValues);

  useSegmentNotifications({
    getError: segmentError ?? attributesError, // error is shown if segment or attributes cannot load
    isSaveSuccess: addSuccess,
    saveError: addError,
    saveErrorMsg: "Segment creation issue.",
    saveSuccessMsg: "Segment was sucesfully created.",
  });
  useEffect(() => {
    if (addSuccess) {
      navigate.toSegments(); // when segment is duplicated we have the same behaviour as in adding
    }
  }, [addSuccess, navigate]);
  useEffect(() => {
    if (segmentResp && attributes) {
      const segmentDuplicated = {
        ...segmentResp.segment,
        name: `Copy of ${segmentResp.segment.name}`,
      };
      setFormValues(values => applySegmentIntoFormValues(values, segmentDuplicated, attributes));
    }
  }, [segmentResp, attributes]);

  if (isAttributesLoading || isSegmentLoading) {
    return <Spinner></Spinner>;
  }

  if (!segmentResp || !attributes) {
    return null;
  }

  return (
    <>
      <SegmentPageTitle title="Duplicate segment" />
      <SegmentForm
        attributes={attributes}
        categories={categories}
        initDisabled={false}
        canSave={true}
        initialValues={formValues}
        isSaving={addIsLoading}
        onSave={duplicate}
        saveText="Create"
      />
    </>
  );
};
