const select = {
  variants: {
    primary: {
      field: {
        w: "400px",
        flexShrink: 0,
        boxShadow: "md",
        borderRadius: "10px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "600",
        __css: {
          "& > div": {
            borderRadius: "10px",
            borderColor: "#7a7171",
            _focus: {
              borderColor: "#7a7171",
              boxShadow: "none",
            },
          },
          ".select__indicators div": {
            bg: "white",
          },
          ".select__single-value span:first-of-type": {
            fontSize: "14px",
            lineHeight: "14px",
            marginTop: "2px",
          },
          ".select__single-value span:last-child": {
            textOverflow: "ellipsis",
            width: "335px",
            whiteSpace: "nowrap",
            display: "inline-block",
            overflow: "hidden",
          },
        },
        _focus: {},
        _active: {},
        _hover: {},
      },
    },
  },
};

export default select;
