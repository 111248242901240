import { Box } from "@chakra-ui/react";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import { Menu } from "./Menu";

export const Help = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header path={[t("headers.help")]} />
      <Box m="50px auto 0">
        <Menu />
      </Box>
    </>
  );
};
