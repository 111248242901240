import { Button, Flex, Text } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";

import { DatePickerMode } from "./types";

type ExampleCustomInputProps = {
  mode: DatePickerMode;
  value?: Date;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const CustomInput = forwardRef<HTMLButtonElement, ExampleCustomInputProps>(
  ({ mode, value, isDisabled = false, onClick }, ref) => {
    const text = useMemo(() => {
      if (mode === DatePickerMode.From) {
        return "From:";
      } else if (mode === DatePickerMode.Until) {
        return "Until:";
      } else {
        return "Between:";
      }
    }, [mode]);

    return (
      <Flex alignItems="center">
        <Text fontWeight="500" mr={2}>
          {text}
        </Text>
        <Button
          maxW="250px"
          bg="#eee"
          transition="background ease 500ms"
          onClick={onClick}
          ref={ref}
          _hover={{
            bg: isDisabled ? "eee" : "#ccc",
            cursor: isDisabled ? "not-allowed" : "pointer",
          }}
          _focus={{}}
        >
          {value}
        </Button>
      </Flex>
    );
  }
);
