import { useCallback, useEffect } from "react";

import { REAUTHENTICATE_INTERVAL, REFRESH_TOKEN_TIME_GAP_IN_SECONDS } from "config/constants";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { useLogoutMutation, useRefreshTokenMutation } from "api/auth";
import { setCredentials } from "redux/features/authSlice";
import { useNavigation } from "./useNavigation";
import { useLocation } from "react-router-dom";

const shouldRefreshToken = (expireTime: number): boolean => {
  // Access token is valid for 15 minutes, but we want to refresh access token
  // REFRESH_TOKEN_TIME_GAP_IN_SECONDS seconds before expiration
  const currentTimePlusTimeGap = Math.floor(Date.now() / 1000 + REFRESH_TOKEN_TIME_GAP_IN_SECONDS);

  return expireTime === null || currentTimePlusTimeGap > expireTime;
};

export const useReauthenticateInterval = () => {
  const tokenExpireTime = useAppSelector((state: RootState) => state.auth.expireTime);
  const [refreshToken, { data: token, isError: isRefreshTokenError }] = useRefreshTokenMutation();
  const [logout] = useLogoutMutation();
  const navigate = useNavigation();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const refresh = useCallback(() => {
    if (!tokenExpireTime) {
      return;
    }
    if (shouldRefreshToken(tokenExpireTime)) {
      refreshToken();
    }
  }, [token]);

  useEffect(() => {
    if (tokenExpireTime) {
      refresh();
      const id = setInterval(refresh, REAUTHENTICATE_INTERVAL);
      return () => {
        clearInterval(id);
      };
    } else {
      navigate.toLogin();
    }
  }, [location.key]); // refresh every page change

  useEffect(() => {
    if (token?.expire_time) {
      dispatch(setCredentials({ token: token.access_token, expireTime: token.expire_time }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isRefreshTokenError) {
      logout();
      navigate.toLogin();
    }
  }, [isRefreshTokenError, logout, navigate]);
};
