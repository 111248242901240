import { Box, Image, Text, Link, Icon } from "@chakra-ui/react";
import { NavLink as RouterLink } from "react-router-dom";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { BiCube } from "react-icons/bi";

import TclLogo from "assets/images/tcl-logo.svg";
import { ROUTES } from "config/routes";

export const Sidebar = () => (
  <Box
    pos="fixed"
    w="90px"
    h="100%"
    bg="brand.gray"
    p="30px 10px"
    display="flex"
    flexDir="column"
    alignItems="center"
    zIndex="1"
  >
    <Image src={TclLogo} alt="TCL logo" />
    <Text color="white" fontWeight="700" textAlign="center" mb="80px">
      OBDP
    </Text>
    <Link
      as={RouterLink}
      to={ROUTES.SEGMENTS.LIST}
      _focus={{}}
      _activeLink={{
        "& > svg": {
          color: "brand.red",
        },
      }}
    >
      <Icon as={BiCube} color="white" boxSize={10} />
    </Link>
    <Link
      as={RouterLink}
      to={ROUTES.HELP}
      mt="auto"
      _focus={{}}
      _activeLink={{
        "& > svg": {
          color: "brand.red",
        },
      }}
    >
      <Icon as={IoMdHelpCircleOutline} color="white" boxSize={10} />
    </Link>
  </Box>
);
