import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSegment, useUpdateSegment } from "api/crud";
import { Operation } from "types/services/segments.definitions";
import {
  filterOutMandatoryFormConditions,
  filterOutMandatoryQueryItems,
  mapQueryToFormConditions,
} from "../utils/segmentDataTransformation";
import { useMergedSegmentAttributes } from "api/segmentsAllRegions";
import { FormValues, SegmentForm } from "./SegmentForm";
import { applySegmentIntoFormValues, formInitValues, useSaveSegment, useSegmentNotifications } from "./utils";
import { SegmentPageTitle } from "./components";

export const EditSegment = () => {
  const params = useParams();
  const {
    attributes,
    categories,
    isLoading: isAttributesLoading,
    error: attributesError,
  } = useMergedSegmentAttributes();
  const {
    mutate: update,
    isSuccess: updateSuccess,
    error: updateError,
    isLoading: updateLoading,
  } = useUpdateSegment(params.id as string);
  const { isLoading: isSegmentLoading, data: segmentResp, error: segmentError } = useGetSegment(params.id as string);
  const save = useSaveSegment(update);

  const [formValues, setFormValues] = useState<FormValues>(formInitValues);
  useSegmentNotifications({
    getError: segmentError ?? attributesError, // error is shown if segment or attributes cannot load
    isSaveSuccess: updateSuccess,
    saveError: updateError,
    saveErrorMsg: "Segment update issue.",
    saveSuccessMsg: "Segment was sucesfully updated.",
  });
  useEffect(() => {
    if (segmentResp && attributes) {
      setFormValues(values => applySegmentIntoFormValues(values, segmentResp.segment, attributes));
    }
  }, [segmentResp, attributes]);

  if (isAttributesLoading || isSegmentLoading) {
    return <Spinner></Spinner>;
  }
  if (!segmentResp || !attributes) {
    return null;
  }

  return (
    <>
      <SegmentPageTitle title="Edit Segment" />
      <SegmentForm
        attributes={attributes}
        categories={categories}
        canSave={segmentResp?.access.includes("write")}
        initDisabled={true}
        initialValues={formValues}
        isSaving={updateLoading}
        onSave={save}
        saveText="Save"
      />
    </>
  );
};
