import { Box, Text, Flex, Button, Icon } from "@chakra-ui/react";
import { useNavigation } from "hooks/useNavigation";
import { FC } from "react";
import { BsPlusLg } from "react-icons/bs";

import { SearchBar } from "./SearchBar";

type HeaderProps = {
  onChange: (value: string) => void;
};

export const Header: FC<HeaderProps> = ({ onChange }) => {
  const navigate = useNavigation();

  return (
    <>
      <Text as="h1" fontWeight="500" fontSize="32px" mb="30px">
        User Segmentation
      </Text>
      <Flex alignItems="center" mb="30px">
        <Box w="500px">
          <SearchBar onChange={onChange} />
        </Box>
        <Button
          variant="cta"
          ml="auto"
          leftIcon={<Icon as={BsPlusLg} boxSize="12px" />}
          onClick={() => {
            localStorage.setItem("add-segment", "");
            navigate.toAddSegment();
          }}
        >
          Add
        </Button>
      </Flex>
    </>
  );
};
