import DatePickerBase from "react-datepicker";
import { FC, useEffect, useState } from "react";

import { CustomCalendarContainer } from "./CustomCalendarContainer";
import { CustomInput } from "./CustomInput";
import { DatePickerMode } from "./types";
import { format } from "date-fns";
import { Operation } from "types/services/segments.definitions";

type ValueExternal = string[];
type ValueInternal = Date[];

const toInternal = (ext: ValueExternal): ValueInternal => ext.map(val => new Date(val));
const toExternal = (int: ValueInternal): ValueExternal => int.map(val => format(val, "yyyy-MM-dd"));

const toMode = (operation: Operation): DatePickerMode =>
  operation === Operation.LessThan
    ? DatePickerMode.Until
    : operation === Operation.GreaterThan
    ? DatePickerMode.From
    : DatePickerMode.Between;

const toOperation = (mode: DatePickerMode): Operation =>
  mode == DatePickerMode.Until
    ? Operation.LessThan
    : mode === DatePickerMode.From
    ? Operation.GreaterThan
    : Operation.Between;

export const DatePicker: FC<{
  initValue: ValueExternal;
  operation: Operation;
  isDisabled?: boolean;
  onChange: (dates: ValueExternal, operation: Operation) => void;
}> = ({ initValue, operation, isDisabled = false, onChange }) => {
  const [value, setValue] = useState<ValueInternal>(initValue.length > 0 ? toInternal(initValue) : [new Date()]);
  const [mode, setMode] = useState<DatePickerMode>(toMode(operation));
  const onChangeHandler = (date: Date | null | (Date | null)[]) => {
    if (date === null) {
      return;
    }
    const value = Array.isArray(date) ? (date.filter(d => d !== null) as Date[]) : [date];
    setValue(value);
    onChange(toExternal(value), toOperation(mode));
  };

  const onChangeMode = (mode: DatePickerMode) => {
    setMode(mode);
    const value = mode === DatePickerMode.Between ? [new Date(), new Date()] : [new Date()];
    onChangeHandler(value);
  };

  useEffect(() => {
    onChange(toExternal(value), toOperation(mode));
  }, []);

  return (
    <DatePickerBase
      disabled={isDisabled}
      selectsRange={mode === DatePickerMode.Between}
      selected={value[0]}
      startDate={value[0]}
      endDate={value[1]}
      monthsShown={2}
      onChange={onChangeHandler}
      dateFormat="yyyy/MM/dd"
      calendarContainer={props => <CustomCalendarContainer {...props} mode={mode} setMode={onChangeMode} />}
      customInput={<CustomInput mode={mode} isDisabled={isDisabled} />}
    />
  );
};
