import { ROUTES } from "config/routes";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Segment } from "types/services/segments.definitions";

export const useNavigation = () => {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      toAddSegment: () => navigate(ROUTES.SEGMENTS.ADD),
      toEditSegment: (id: string) => navigate(ROUTES.SEGMENTS.EDIT.replace(":id", id)),
      toSegments: () => navigate(ROUTES.SEGMENTS.LIST),
      toHelp: () => navigate(ROUTES.HELP),
      toDuplicateSegment: (id: string) => navigate(ROUTES.SEGMENTS.DUPLICATE.replace(":id", id)),
      toLogin: () => navigate(ROUTES.LOGIN),
    }),
    [navigate]
  );
};
