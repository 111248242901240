import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginRequest, LoginResponse } from "types/services/auth.types";

export const refreshToken = async () => {
  const data = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/refresh_token`, {
    method: "POST",
  });
  if (data.status !== 200) {
    throw new Error("Refreshing token error");
  }

  return data.json();
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH_API_URL,
  }),
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ email, password }) => ({
        url: "login",
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
        credentials: "include",
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "logout",
        method: "DELETE",
        credentials: "include",
      }),
    }),
    refreshToken: builder.mutation<{ expire_time: number; access_token: string }, void>({
      query: () => ({
        url: "refresh_token",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useRefreshTokenMutation } = authApi;
