const button = {
  variants: {
    clear: {
      border: "none",
      fontWeight: "600",
      _focus: {
        boxShadow: "none",
      },
      _active: {},
      _hover: {},
    },
    cta: {
      bgColor: "brand.red",
      color: "white",
      fontWeight: "500",
      borderRadius: "10px",
      boxShadow: "md",
      _disabled: {
        _hover: {
          bgColor: "brand.red",
        },
      },
      _hover: {
        _disabled: {
          bgColor: "brand.red",
        },
      },
      _focus: {},
      _active: {},
    },
    secondary: {
      bgColor: "white",
      color: "brand.gray",
      fontWeight: "500",
      borderRadius: "10px",
      boxShadow: "md",
      _hover: {},
      _focus: {},
      _active: {},
    },
  },
};

export default button;
