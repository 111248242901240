import { useQueryClient } from "@tanstack/react-query";
import { isDuplicateNameError, SegmentRequest } from "api/crud";
import { useNavigation } from "hooks/useNavigation";
import { useNotification } from "hooks/useNotification";
import { useEffect } from "react";
import { getAuth, useAppSelector } from "store";
import {
  Operation,
  Segment,
  SegmentsAttribute,
  SegmentsAttributeInputType,
  SegmentsResponse,
} from "types/services/segments.definitions";
import { LAST_LOGIN_COUNTRY, REGISTER_COUNTRY } from "../constants";
import {
  filterOutMandatoryFormConditions,
  filterOutMandatoryQueryItems,
  mapQueryToFormConditions,
  mapToQuery,
} from "../utils/segmentDataTransformation";
import { FormValues } from "./SegmentForm";

export function useSaveSegment<T>(apiCall: (mutation: SegmentRequest) => T) {
  const owner = useAppSelector(getAuth).username;
  const queryClient = useQueryClient();
  return (values: FormValues) => {
    let conditions = values.conditions;
    if (values.targetSystem === "CMS")
      conditions = [
        ...conditions,
        {
          option: { label: LAST_LOGIN_COUNTRY, value: LAST_LOGIN_COUNTRY },
          subOption: values.lastLoginCountry,
          inputType: SegmentsAttributeInputType.MultiSelect,
          values: [], // not important at this point
          category: "", // not important at this point
        },
      ];
    else if (values.targetSystem === "IMS")
      conditions = [
        ...conditions,
        {
          option: { label: REGISTER_COUNTRY, value: REGISTER_COUNTRY },
          inputType: SegmentsAttributeInputType.SingleSelectKeyValue,
          subOption: [values.registerCountry],
          values: [], // not important at this point
          category: "", // not important at this point
        },
      ];
    apiCall({
      ...values,
      owner,
      query: mapToQuery(conditions),
    });
    // invalidate counts of segments during the update
    queryClient.invalidateQueries({ queryKey: ["count"] });
  };
}

export const useSegmentNotifications = ({
  getError,
  saveError,
  isSaveSuccess,
  saveErrorMsg,
  saveSuccessMsg,
}: {
  getError?: Error | unknown;
  saveError?: Error | unknown;
  isSaveSuccess: boolean;
  saveErrorMsg: string;
  saveSuccessMsg: string;
}) => {
  const { showError, showSuccess } = useNotification();
  const navigate = useNavigation();
  useEffect(() => {
    if (getError) {
      showError("Segment could not be loaded.");
      navigate.toSegments();
    }
  }, [getError]);

  useEffect(() => {
    if (isSaveSuccess) {
      showSuccess(saveSuccessMsg);
    }
    if (saveError instanceof Error) {
      if (isDuplicateNameError(saveError)) {
        showError("The name of the segment is already used. Please use another one.");
      } else {
        showError(saveErrorMsg);
      }
    }
  }, [isSaveSuccess, saveError]);
};

export const formInitValues: FormValues = {
  name: "",
  description: "",
  targetSystem: null,
  registerCountry: "",
  lastLoginCountry: [],
  conditions: [],
};
Object.freeze(formInitValues);
Object.freeze(formInitValues.conditions);

export const applySegmentIntoFormValues = (values: FormValues, segment: Segment, attributes: SegmentsAttribute[]) => {
  let query = segment.query;
  if (query.operation === Operation.And) {
    query = { ...query, items: query.items.filter(filterOutMandatoryQueryItems) };
  }
  const newValues = { ...values };
  newValues.name = segment?.name;
  newValues.description = segment?.description;
  newValues.targetSystem = segment?.target_system ?? null;
  newValues.registerCountry = segment?.register_country;
  newValues.lastLoginCountry = segment?.last_login_country;
  newValues.conditions = mapQueryToFormConditions(query, attributes).filter(filterOutMandatoryFormConditions);
  return newValues;
};
