import { Flex, Button } from "@chakra-ui/react";
import { FC, useEffect } from "react";

import { FilterNumberMode } from "./types";

export const ModeSelector: FC<{
  mode: FilterNumberMode;
  setMode: (mode: FilterNumberMode) => void;
  onClose: () => void;
}> = ({ setMode, onClose, mode = FilterNumberMode.From }) => {
  useEffect(() => {
    onClose();
  }, [mode, onClose]);

  return (
    <Flex w="100%">
      <Button
        flex="1"
        px={4}
        bg={mode === FilterNumberMode.From ? "brand.red" : "white"}
        color={mode === FilterNumberMode.From ? "white" : "black"}
        _hover={{}}
        _focus={{}}
        onClick={() => setMode(FilterNumberMode.From)}
      >
        Greater than
      </Button>
      <Button
        flex="1"
        bg={mode === FilterNumberMode.Until ? "brand.red" : "white"}
        color={mode === FilterNumberMode.Until ? "white" : "black"}
        _hover={{}}
        _focus={{}}
        onClick={() => setMode(FilterNumberMode.Until)}
      >
        Less than
      </Button>
      <Button
        flex="1"
        bg={mode === FilterNumberMode.Between ? "brand.red" : "white"}
        color={mode === FilterNumberMode.Between ? "white" : "black"}
        _hover={{}}
        _focus={{}}
        onClick={() => setMode(FilterNumberMode.Between)}
      >
        Between
      </Button>
    </Flex>
  );
};
