const menu = {
  parts: ["item"],
  variants: {
    action: {
      item: {
        _active: {},
        _focus: { bg: "none" },
        _hover: { bg: "none", color: "brand.red" },
      },
    },
  },
};

export default menu;
