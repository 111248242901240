import { Text, Icon } from "@chakra-ui/react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export const SegmentPageTitle = ({ title }: { title: string }) => {
  return (
    <Text as="h1" fontWeight="500" fontSize="32px" mb="30px" display="flex" alignItems="center">
      User Segmentation
      <Icon as={MdOutlineKeyboardArrowRight} mx={3} />
      <Text as="span" color="brand.red">
        {title}
      </Text>
    </Text>
  );
};
