const globalTemp = {
  html: {
    h: "100%",
  },
  body: {
    h: "100%",
  },
  "#root": {
    h: "100%",
  },
};

export default globalTemp;
