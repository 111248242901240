import {
  SegmentQuery,
  SegmentsAttribute,
  Operation,
  FieldCondition,
  FormCondition,
  Segment,
  MultiSelectFormCondition,
  SingleSelectFormCondition,
  MultiSelectKeyValueCondition,
  AllowForPickingOperatorConditions,
  makeNoCondition,
  AvailableFormCondition,
  isNoCondition,
  SegmentsAttributesResponse,
} from "types/services/segments.definitions";
import { MANDATORY_SEGMENT_ATTRIBUTES } from "../constants";

const isInpuTypeSelect = (
  formCondition: AvailableFormCondition
): formCondition is MultiSelectFormCondition | SingleSelectFormCondition => {
  return ["multi_select", "single_select_key_value"].includes(formCondition.inputType);
};

const isInputTypeKeyValueSelect = (formCondition: FormCondition): formCondition is MultiSelectKeyValueCondition => {
  return formCondition.inputType === "multi_select_key_value";
};

const isInputTypeAllowPickingOperator = (
  inputType: AvailableFormCondition["inputType"]
): inputType is "date" | "number" => {
  return ["date", "number"].includes(inputType);
};

export const mapFormConditionToQuery = (formCondition: AvailableFormCondition): FieldCondition => {
  let operation = Operation.Equal;
  if (isInpuTypeSelect(formCondition)) {
    operation = Operation.Equal;
  } else if (isInputTypeKeyValueSelect(formCondition)) {
    operation = Operation.GreaterOrEqual;
  } else {
    // here we have field which set operation
    operation = formCondition.operation;
  }

  return {
    operation: operation,
    field: formCondition.option.value,
    value: formCondition.subOption,
  };
};

export const mapToQuery = (conditions: FormCondition[]): Segment["query"] => {
  if (conditions.length > 1) {
    return {
      operation: Operation.And,
      items: conditions
        .map(cond => {
          return isNoCondition(cond) ? null : mapFormConditionToQuery(cond);
        })
        .filter((cond): cond is FieldCondition => cond !== null),
    };
  }
  if (isNoCondition(conditions[0])) {
    throw new Error("No conditions in segment.");
  }
  return mapFormConditionToQuery(conditions[0]);
};

export const mapSingleQueryToFormCondition = (query: FieldCondition, attr: SegmentsAttribute): FormCondition => {
  if (isInputTypeAllowPickingOperator(attr.inputType)) {
    return {
      option: { value: query.field, label: attr.description },
      subOption: query.value,
      operation: query.operation,
      inputType: attr.inputType,
      values: attr.values,
      category: attr.category,
    } as AllowForPickingOperatorConditions;
  } else {
    return {
      option: { value: query.field, label: attr.description },
      subOption: query.value,
      inputType: attr.inputType,
      values: attr.values,
      category: attr.category,
    } as Exclude<FormCondition, AllowForPickingOperatorConditions>;
  }
};

export const defaultSubOptionForAttr = (attr: SegmentsAttribute) => {
  return attr.inputType === "multi_select_key_value" ? [attr.values[0].value, 0] : [];
};

export const changeOptionInFormCondition = (
  condition: FormCondition,
  getAttrByFieldName: (name: string) => SegmentsAttribute,
  option: AvailableFormCondition["option"]
): FormCondition => {
  const attr = getAttrByFieldName(option.value);
  return {
    ...condition,
    inputType: attr.inputType,
    values: attr.values,
    option: option,
    subOption: defaultSubOptionForAttr(attr),
  } as FormCondition;
};

export const changeSubOptionInFormCondition = (
  condition: FormCondition,
  subOption: FormCondition["subOption"],
  operation?: Operation
): FormCondition => {
  return {
    ...condition,
    ...(operation ? { operation } : {}),
    subOption,
  } as FormCondition;
};

export const mapQueryToFormConditions = (query: SegmentQuery, attributes: SegmentsAttribute[]): FormCondition[] => {
  const findAttrByFieldName = (fieldName: string) => {
    return attributes.find(attr => attr.name == fieldName);
  };
  if ("items" in query) {
    // AND on TOP
    return (
      query.items
        ?.filter(item => !!findAttrByFieldName(item.field)) // left these fields which have attributes provided
        // TODO - if we will filter such fields it means that user will not see an error, but will not see
        // some parts of the segment, only saving it will make this field removed.
        // in other words you can have situation that segment has some condition which are not visible in the form
        // I think in this situation we should inform the user that the segment is corrupted or smth
        .map(item => {
          const attr = findAttrByFieldName(item.field) as SegmentsAttribute; // we filtered undefined already
          return mapSingleQueryToFormCondition(item, attr);
        })
    );
  }
  // ONLY ONE CONDITION
  const attr = findAttrByFieldName(query.field);
  return attr ? [mapSingleQueryToFormCondition(query, attr)] : [];
};

export const onlyNotMandatoryAttributes = (attribute: SegmentsAttribute) => {
  return attribute.category !== "mandatory_field";
};

export const onlyNotMandatoryCategory = (category: SegmentsAttributesResponse["categories"][0]) => {
  return category.name !== "mandatory_field";
};

export const filterOutMandatoryQueryItems = (cond: FieldCondition) => {
  return !MANDATORY_SEGMENT_ATTRIBUTES.has(cond.field);
};

export const filterOutMandatoryFormConditions = (cond: FormCondition) => {
  return !MANDATORY_SEGMENT_ATTRIBUTES.has(cond.option.value);
};
