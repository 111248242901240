import { Text, Icon } from "@chakra-ui/react";
import React, { Fragment, FC } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

type HeaderProps = {
  path: string[];
};

export const Header: FC<HeaderProps> = ({ path }) => {
  const lastItem = path.slice(-1).map(item => (
    <Text as="span" color="brand.red">
      {item}
    </Text>
  ))[0];

  const itemsWithoutLast = path.slice(0, -1).map(item => (
    <Fragment key={item}>
      {item}
      <Icon as={MdOutlineKeyboardArrowRight} mx={3} />
    </Fragment>
  ));

  return (
    <>
      <Text as="h1" fontWeight="500" fontSize="32px" mb="30px" display="flex" alignItems="center">
        {itemsWithoutLast}
        {lastItem}
      </Text>
    </>
  );
};
