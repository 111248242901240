const table = {
  parts: [],
  variants: {
    segments: {
      table: {
        bg: "#fff",
      },
      th: {
        fontSize: "14px",
        borderBottom: "1px solid #d7d7d7",
        py: "8px",
      },
      td: {
        fontSize: "12px",
        py: "8px",
      },
      tbody: {
        tr: {
          "&:nth-of-type(even)": {
            td: {
              bgColor: "rgba(51, 51, 51, 0.05)",

              "&:first-of-type": {
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              },

              "&:last-child": {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              },
            },
          },
        },
      },
    },
  },
};

export default table;
