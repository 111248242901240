import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react";
import { Component } from "react";

export class ErrorBoundary extends Component {
  state: {
    hasError: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>Error occured, please contact IT support.</AlertDescription>
        </Alert>
      );
    }

    return this.props.children;
  }
}
