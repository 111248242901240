import { Box, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useDeleteSegment, useGetSegments } from "api/crud";
import { useEffect, useState } from "react";
import { Segment } from "types/services/segments.definitions";
import { useTrack } from "hooks/useTrack";
import { usePagination } from "hooks/usePagination";
import debounce from "lodash/debounce";

import { TableData } from "./TableData";
import { Header } from "./Header";
import { ConfirmationDeleteModal } from "./ConfirmationDeleteModal";
import { useNotification } from "hooks/useNotification";

export const SegmentsList = () => {
  const { data: segments, isLoading: isSegmentsLoading, isError: isSegmentsError } = useGetSegments();
  const {
    mutate: deleteSegment,
    isSuccess: deleteSuccess,
    isLoading: isDeleting,
    isError: deleteError,
  } = useDeleteSegment();

  const [currentSegmentToDelete, setCurrentSegmentToDelete] = useState<Segment | null>(null);
  const [filteredSegments, setFilteredSegments] = useState<Segment[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showError, showSuccess } = useNotification();
  const { track } = useTrack("segments_list_enter");

  const { itemOffset, currentPage, pageCount, currentItems, handleNextPageClick, handlePrevPageClick } =
    usePagination<Segment>(filteredSegments);

  const confirmModal = (segment: Segment) => {
    setCurrentSegmentToDelete(segment);

    onOpen();
  };

  const clickDeleteSegmentHandler = () => {
    if (currentSegmentToDelete?.id) {
      deleteSegment(currentSegmentToDelete.id);
    }
  };

  const searchOnChangeHandler = debounce((value: string) => {
    const searchValue = value.toLowerCase();
    const filteredSegments =
      segments?.filter(
        segment =>
          segment?.name?.toLowerCase().includes(searchValue) ||
          segment?.description?.toLowerCase().includes(searchValue) ||
          segment?.owner?.toLowerCase().includes(searchValue) ||
          segment?.target_system?.toLowerCase().includes(searchValue)
      ) || [];
    setFilteredSegments(filteredSegments);
  }, 500);

  useEffect(() => {
    if (deleteSuccess) {
      onClose();
      track("segment_delete");
      showSuccess("Segment deleted successfully.");
    }
    if (deleteError) {
      onClose();
      showError("Segment has not been deleted. Only segment owner can remove the segment.");
    }
  }, [deleteSuccess, deleteError, onClose, track]);

  useEffect(() => {
    if (segments) {
      setFilteredSegments(segments);
    }
  }, [segments]);

  return (
    <>
      <Header onChange={searchOnChangeHandler} />
      <Box pos="relative">
        <TableData
          dataSize={filteredSegments.length}
          itemOffset={itemOffset}
          currentPage={currentPage}
          pageCount={pageCount}
          currentItems={currentItems}
          handleNextPageClick={handleNextPageClick}
          handlePrevPageClick={handlePrevPageClick}
          onDelete={confirmModal}
        />
        {isSegmentsLoading && (
          <Box as="span" display="block" pos="absolute" top="100px" left="50%" transform="translateX(-50%)">
            <Spinner />
          </Box>
        )}
        {!isSegmentsLoading &&
          (isSegmentsError ||
            (currentItems.length === 0 && (
              <Box as="span" display="block" pos="absolute" top="75px" left="50%" transform="translateX(-50%)">
                <Text>No segments.</Text>
              </Box>
            )))}
        <ConfirmationDeleteModal
          isOpen={isOpen}
          isDeleting={isDeleting}
          segment={currentSegmentToDelete}
          onDelete={clickDeleteSegmentHandler}
          onClose={onClose}
        />
      </Box>
    </>
  );
};
