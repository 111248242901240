import { Flex, Text, chakra } from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import { FC, useMemo, useState } from "react";

const ChakraSelect = chakra(ReactSelect);

export type Option = {
  label: string;
  value: string;
};

type SelectProps = {
  name: string;
  value: string[];
  options: Option[] | string[];
  defaultValue?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  width?: string;
  getOptionDescription?: (option: Option) => string;
  onChange: (value: Option[]) => void;
};

const intoOption = (option: string | Option) => {
  if (typeof option === "string") {
    return { label: option, value: option };
  } else {
    return option;
  }
};

const valueIntoOption = (options: SelectProps["options"]) => (value: string) => {
  if (typeof value === "string") {
    return { label: options.map(intoOption).find(opt => opt.value === value)?.label ?? value, value };
  } else {
    return value;
  }
};

export const Select: FC<SelectProps> = ({
  name,
  value,
  options,
  defaultValue = null,
  isLoading = false,
  isDisabled = false,
  isMulti = false,
  width = "400px",
  onChange,
  getOptionDescription,
}) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const onChangeHandler = (values: Option[] | Option) => {
    onChange(Array.isArray(values) ? values : [values as Option]);
  };

  const optionsWithLabels = useMemo(() => options.map(intoOption), [options]);

  const valuesWithLabels = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map(valueIntoOption(options));
    } else {
      return intoOption(value);
    }
  }, [value]);

  return (
    <ChakraSelect
      isMulti={isMulti}
      isDisabled={isDisabled}
      name={name}
      value={valuesWithLabels}
      defaultValue={defaultValue}
      isLoading={isLoading}
      getOptionLabel={(option: Option) =>
        option.label
          ? ((
              <Flex flexDir="column">
                <Text as="span">{`${option.label}${isDescriptionVisible ? ":" : ""}`}</Text>
                {isDescriptionVisible && (
                  <Text as="span" fontSize="10px" color="#999">
                    {getOptionDescription ? getOptionDescription(option) : ""}
                  </Text>
                )}
              </Flex>
            ) as any)
          : "Select..."
      }
      getOptionValue={(option: Option) => option?.value}
      options={optionsWithLabels}
      onChange={onChangeHandler}
      onMenuOpen={() => getOptionDescription && setIsDescriptionVisible(true)}
      onMenuClose={() => setIsDescriptionVisible(false)}
      flexShrink={0}
      w={width}
      boxShadow="md"
      size="sm"
      borderRadius="10px"
      cursor="pointer"
      fontSize="14px"
      fontWeight="600"
      classNamePrefix="select"
      __css={{
        "& > div": {
          borderRadius: "10px",
          borderColor: "#7a7171",
          _focus: {
            borderColor: "#7a7171",
            boxShadow: "none",
          },
        },
        ".select__indicators div": {
          bg: "white",
        },
        ".select__single-value span": {
          height: "16px"
        },
        ".select__single-value span:first-of-type": {
          fontSize: "14px",
          lineHeight: "14px",
          marginTop: "2px",
        },
        ".select__single-value span:last-child": {
          textOverflow: "ellipsis",
          width: "335px",
          whiteSpace: "nowrap",
          display: "inline-block",
          overflow: "hidden",
        },
        ".select__control:hover": {
          borderColor: "#bf0008",
        },
        ".select__option--is-focused": {
          color: "#bf0008",
          background: "transparent",
        },
        ".select__multi-value__remove svg:hover": {
          color: "#bf0008",
        },
        ".select__multi-value": {
          background: "#666",
          color: "#fff",
        },
        ".select__control--is-disabled .select__multi-value__remove svg:hover": {
          cursor: "not-allowed",
          color: "currentColor",
        },
      }}
      _focus={{}}
      _active={{}}
    />
  );
};
