import { Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAddSegment } from "api/crud";
import { useNavigation } from "hooks/useNavigation";
import { useMergedSegmentAttributes } from "api/segmentsAllRegions";
import { SegmentForm } from "./SegmentForm";
import { formInitValues, useSaveSegment, useSegmentNotifications } from "./utils";
import { SegmentPageTitle } from "./components";

export const AddSegment = () => {
  const navigate = useNavigation();
  const {
    attributes,
    categories,
    isLoading: isAttributesLoading,
    error: attributesError,
  } = useMergedSegmentAttributes();
  const { mutate: addSegment, isLoading: addLoading, isSuccess: addSuccess, error: addError } = useAddSegment();
  const add = useSaveSegment(addSegment);

  useSegmentNotifications({
    getError: attributesError, // error is shown if attributes cannot load
    isSaveSuccess: addSuccess,
    saveError: addError,
    saveErrorMsg: "Segment creation issue.",
    saveSuccessMsg: "Segment was sucesfully created.",
  });

  useEffect(() => {
    if (addSuccess) {
      navigate.toSegments();
    }
  }, [addSuccess, navigate]);

  if (isAttributesLoading) {
    return <Spinner></Spinner>;
  }
  if (attributesError) {
    throw new Error("Attributes for the form could not be downloaded.");
  }

  if (!attributes) {
    return null;
  }
  return (
    <>
      <SegmentPageTitle title="Create Segment" />
      <SegmentForm
        attributes={attributes}
        categories={categories}
        initDisabled={false}
        canSave={true}
        initialValues={formInitValues}
        isSaving={addLoading}
        onSave={add}
        saveText={"Create"}
      />
    </>
  );
};
