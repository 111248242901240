import { useCallback, useEffect } from "react";
import { RootState, useAppSelector } from "store";

export type TRACK_EVENTS =
  | "login_success"
  | "login_fail"
  | "segments_list_enter"
  | "segment_details_enter"
  | "segment_details_edit_enter"
  | "segment_delete"
  | "pagination_next_page"
  | "pagination_prev_page"
  | "segment_creation_error"
  | "segment_creation"
  | "segment_update";

export const useTrack = (enterId?: TRACK_EVENTS) => {
  const userEmail = useAppSelector((state: RootState) => state.auth.username);

  const track = useCallback(
    (id: TRACK_EVENTS, data = {}) => window?.sensors?.track(id, { user_email: userEmail, ...data }),
    [userEmail]
  );
  const login = useCallback((userId: string) => window?.sensors?.login(userId), []);

  useEffect(() => {
    if (enterId) {
      track(enterId);
    }
  }, [enterId, track]);

  return { track, login };
};
