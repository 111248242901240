import { FC, memo } from "react";
import { Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import { Pagination } from "components/Pagination";
import { HelpAttribute } from "./types";
import { useTranslation } from "react-i18next";
import { getLabel } from "modules/UserSegmentation/utils/getLabel";

export const AttributesData: FC<{
  itemOffset: number;
  currentPage: number;
  pageCount: number;
  dataSize: number;
  currentItems: HelpAttribute[];
  handleNextPageClick: () => void;
  handlePrevPageClick: () => void;
}> = memo(
  ({
    itemOffset,
    currentPage,
    pageCount,
    dataSize,
    currentItems,
    handleNextPageClick,
    handlePrevPageClick,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        {currentItems.length > 0 && (
          <Pagination
            itemOffset={itemOffset}
            currentPage={currentPage}
            pageCount={pageCount}
            dataSize={dataSize}
            handlePrevPageClick={handlePrevPageClick}
            handleNextPageClick={handleNextPageClick}
          />
        )}
        <Table variant="segments">
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Comment</Th>
            </Tr>
          </Thead>
          <Tbody pos="relative">
            {currentItems?.map(item => (
              <Tr key={item.name}>
                <Td>{t(`categories.${item.category}`, getLabel(item.category))}</Td>
                <Td>{t(`attributes.${item.name}`, getLabel(item.name))}</Td>
                <Td>{item.description}</Td>
                <Td>{t(`attributes_comments.${item.name}`)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </>
    );
  }
);
