import {
  Box,
  Flex,
  Stack,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
  Image,
  Icon,
  useToast,
  chakra,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { setCredentials, setUser } from "redux/features/authSlice";
import { useLoginMutation } from "api/auth";
import { useAppDispatch } from "store";
import TclLogo from "assets/images/tcl-logo.svg";
import { useTrack } from "hooks/useTrack";
import { useNavigation } from "hooks/useNavigation";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginTrigger, { data, isError, isSuccess, isLoading }] = useLoginMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigation();
  const toast = useToast();
  const { track } = useTrack();

  const handleShowClick = () => setShowPassword(!showPassword);
  const handleLoginBtnClick = () => {
    loginTrigger({
      email,
      password,
    });
  };

  useEffect(() => {
    if (data?.access_token && data.expire_time) {
      dispatch(setCredentials({ token: data.access_token, expireTime: data.expire_time }));
      dispatch(setUser({ username: email }));
      track("login_success");

      navigate.toSegments();
    }
  }, [isSuccess, data?.access_token, data?.expire_time, dispatch, email, navigate, track]);

  useEffect(() => {
    if (isError) {
      track("login_fail");
      toast({
        position: "top-right",
        title: "Failed.",
        description: "Login was failed.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, toast, track]);

  return (
    <Flex
      flexDirection="column"
      width="100vw"
      height="100vh"
      backgroundColor="brand.lightGray"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        bgColor="brand.gray"
        boxShadow="dark-lg"
        w={{ base: "90%", md: "450px" }}
        py={8}
        px={12}
      >
        <Image src={TclLogo} alt="TCL logo" mb={2} />
        <Heading color="white" fontSize="20px" mb="40px">
          OBDP
        </Heading>
        <Box w="100%">
          <form onSubmit={handleLoginBtnClick}>
            <Stack spacing={6}>
              <FormControl>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<CFaUserAlt color="gray.300" />} />
                  <Input
                    borderRadius="10px"
                    bgColor="white"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" color="gray.300" children={<CFaLock color="gray.300" />} />
                  <Input
                    borderRadius="10px"
                    bgColor="white"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <InputRightElement
                    width="3rem"
                    children={
                      <Flex alignItems="center" onClick={handleShowClick} cursor="pointer">
                        {showPassword ? (
                          <Icon as={AiFillEye} color="brand.gray" boxSize="20px" />
                        ) : (
                          <Icon as={AiFillEyeInvisible} color="brand.gray" boxSize="20px" />
                        )}
                      </Flex>
                    }
                  />
                </InputGroup>
              </FormControl>
            </Stack>
            <Flex justifyContent="center" mt="40px">
              <Button variant="cta" isLoading={isLoading} onClick={handleLoginBtnClick} px="40px" type="submit">
                Login
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
