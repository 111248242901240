import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "assets/locales/en.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
          translation: en
      }
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.changeLanguage("en");

export default i18n;
