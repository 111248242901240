import { useToast } from "@chakra-ui/toast";
import { useCallback } from "react";

export const useNotification = () => {
  const toast = useToast();
  const show = useCallback((toastCall: () => unknown, id?: string) => {
    if (id && toast.isActive(id)) {
      return;
    }
    toastCall();
  }, []);
  return {
    showSuccess: (attrs: { body: string; title?: string; id?: string } | string) => {
      const { body, title, id } = typeof attrs === "object" ? attrs : { body: attrs, id: undefined, title: undefined };
      show(
        () =>
          toast({
            id,
            position: "top-right",
            title: title ?? "Success.",
            description: body,
            status: "success",
            duration: 5000,
            isClosable: true,
          }),
        id
      );
    },
    showError: (attrs: { body: string; title?: string; id?: string } | string) => {
      const { body, title, id } = typeof attrs === "object" ? attrs : { body: attrs, id: undefined, title: undefined };
      show(
        () =>
          toast({
            id,
            position: "top-right",
            title: title ?? "Error.",
            description: body,
            status: "error",
            duration: 5000,
            isClosable: true,
          }),
        id
      );
    },
  };
};
